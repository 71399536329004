import React from "react"
import { graphql } from "gatsby"
import Collapsible from "react-collapsible"
import Layout from "../components/layout"
import ContactMe from "../components/contactMe"
import MainHero from "../components/mainHero"
import { FiChevronDown, FiChevronUp } from "react-icons/fi"
import RenderMarkdown from "../cms/renderMarkdown"
import SEO from "../components/SEO"

const Faq = ({
  data: {
    content: { frontmatter },
  },
}) => {
  const renderQuestion = (question, answer) => {
    const triggerStyle = {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      cursor: "pointer",
    }
    return (
      <Collapsible
        key={question}
        trigger={
          <div style={triggerStyle}>
            <h2 className="is-size-5 is-size-3-tablet">{question}</h2>
            <FiChevronDown className="is-size-5 is-size-3-tablet" />
          </div>
        }
        triggerWhenOpen={
          <div style={triggerStyle}>
            <h2 className="is-size-5 is-size-3-tablet">{question}</h2>
            <FiChevronUp className="is-size-5 is-size-3-tablet" />
          </div>
        }
      >
        <RenderMarkdown md={answer} />
        {/* <p className="box">{answer}</p> */}
      </Collapsible>
    )
  }

  return (
    <Layout>
      <SEO title="Counseling Frequently Asked Questions | Birmingham, MI" />
      <MainHero
        heroImage={frontmatter.hero_image}
        title={frontmatter.title}
        subTitle={frontmatter.subTitle}
        height="is-medium"
      />
      <section className="section">
        <div className="container">
          <div
            style={{
              maxWidth: "850px",
              margin: "auto",
            }}
          >
            {frontmatter.questions_.map(set =>
              renderQuestion(set.question, set.answer)
            )}
          </div>
        </div>
      </section>
      <ContactMe />
    </Layout>
  )
}

export default Faq

export const query = graphql`
  {
    content: mdx(frontmatter: { section: { eq: "faq" } }) {
      frontmatter {
        hero_image {
          childImageSharp {
            sizes(maxWidth: 1920) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        title
        subTitle
        questions_ {
          question
          answer
        }
      }
    }
  }
`
